<template>
  <div class="table-component stock table-vidjet">
    <div class="table-component__header d-flex">
      <ButtonStock class="ml-auto" title="Новое поручение" @click="addCard" />
    </div>
    <div class="table-vidjet">
      <table>
        <thead>
          <colgroup width="45%"></colgroup>
          <colgroup width="15%"></colgroup>
          <colgroup width="10%"></colgroup>
          <colgroup width="25%"></colgroup>
          <tr>
            <th>Содержание</th>
            <th>Срок</th>
            <th>Статус</th>
            <th>Отчет</th>
          </tr>
        </thead>
        <tbody class="mh315">
          <tr v-for="item in items" :key="item.id" @dblclick="viewCard(item.id)">
            <td width="45%" :title="item.assignmentName">
              {{ item.assignmentName }}
            </td>
            <td width="15%">
              {{ $momentFormat(item.deadline, 'DD MMMM YYYY') }}
            </td>
            <td width="10%" :title="item.statusName">
              <IconComponent v-if="isDone(item.statusId)" name="goodSigh" />
              <IconComponent v-else-if="item.overdue || item.postponed" name="banSigh" />
              <IconComponent v-else name="refreshSigh" />
            </td>
            <td width="25%" :title="item.report">
              {{ item.report }}
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <div class="table-component__footer">
      <ButtonStock title="Все поручения" @click="goTo" />
    </div>

    <!-- modal -->
    <ModalComponent title="Форма создания поручения" @close="dialogState = false" v-model="dialogState">
      <EapAssignmentCardAdd
        v-bind:nn="dialogNn"
        v-bind:id="dialogId"
        :countryId="countryId"
        v-on:save="afterSaveCard"
        v-on:close="dialogState = false"
      />
    </ModalComponent>
  </div>
</template>

<script>
  import Constants from '@/common/constants';
  import IconComponent from '@/common/ui/IconComponent.vue';

  import ButtonStock from '@/components/buttons/ButtonStock.vue';
  import ModalComponent from '@/components/modals/ModalComponent.vue';

  import EapAssignmentCardAdd from '../../eap/components/EapAssignmentCardAdd.vue';
  import API from '../api/widgetElement';

  export default {
    name: 'Widget8',
    components: {
      IconComponent,
      ButtonStock,
      ModalComponent,
      EapAssignmentCardAdd,
    },
    props: {
      countryId: {
        type: Number,
        default: null,
      },
    },
    watch: {
      countryId: function (/*newValue*/) {
        this.loadItems();
      },
    },
    data() {
      return {
        items: [],
        dialogState: false,
        dialogNn: 1,
        dialogId: 0,
      };
    },
    created() {
      this.loadItems();
    },
    methods: {
      loadItems() {
        API.getEapAssignment({ countryId: this.countryId }).then((response) => {
          this.items = response.data;
        });
      },
      viewCard(id) {
        this.$router.push({ name: 'EapAssignmentCard', params: { id: id, action: 'edit' } });
      },
      goTo() {
        this.$router.push({ name: 'EapAssignments' });
      },
      addCard() {
        this.dialogState = true;
        this.dialogNn++;
        this.dialogId = 0;
      },
      afterSaveCard() {
        this.dialogState = false;
        this.loadPage(false);
      },
      isDone(statusId) {
        return statusId == Constants.eapAssignemntStatusDoneId;
      },
    },
  };
</script>

<style lang="scss" scoped></style>
